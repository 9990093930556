const faqs = [
  {
    question: "1. ¿Estados Unidos exige seguro de viaje?",
    answer: (
      <div>
        <p>           No es obligatorio contar con un seguro de viaje para viajar a USA.
              Sin embargo, su contratación es altamente recomendable. En caso de
              una emergencia médica, el seguro de viaje puede cubrir los gastos
              médicos, incluidas las visitas al hospital, los medicamentos, los
              exámenes y otros procedimientos médicos necesarios.
</p>
      </div>
    ),
  },
  {
    question: "2. ¿Cuánto cuesta la atención médica en Estados Unidos?",
    answer: (
      <div>
        <p> Estos son los costos promedios de algunos servicios médicos en
              USA:<br/> <br/> - Visita de urgencia: 3.300$<br/> <br/> - Ambulancia: 1.700$<br/> <br/> - Noche de hospitalización: 5.220$<br/> <br/> - adiografía: 550$<br/> <br/>- Habitación de urgencias: 2.306$

 </p> 

      </div>
    ),
  },
  {
    question: "3. Requisitos para entrar a Estados Unidos desde Argentina",
    answer: (
      <div>
        <p>
        Los requisitos para entrar a Estados Unidos desde Argentina son
              los siguientes:<br/> <br/> - Pasaporte válido: los viajeros deben tener un pasaporte argentino válido que no
                expire durante su estadía en los Estados Unidos.<br/> <br/> - Visa o exención de visa: Los
                ciudadanos argentinos deben obtener una visa de no inmigrante o
                una exención de visa antes de viajar a los Estados Unidos. La
                exención de visa es válida solo para viajes de negocios o
                turismo y permite a los viajeros permanecer en los Estados
                Unidos por un máximo de 90 días.

 
        </p> 
      </div>
    ),
  },

];

export default faqs;
